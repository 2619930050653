import { AxiosResponse } from "axios";
import { RuRoles } from "../const/roles";

export function getJwtPayload(token: string) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export function GetRole() {
    const token = localStorage.getItem('accessToken');
    if (!token || token === "undefined") {
        return null;
    }
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace('-', '+').replace('_', '/');
    var role = JSON.parse(window.atob(base64))["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
    return role;
}
export function GetRuRole() {
    var role = GetRole();
    return RuRoles[role]
}

export function validateEmail(email: string) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function FirstUpper(s: string) {
    return s[0].toUpperCase() + s.substring(1).toLowerCase();
}

export function CreateFile(reqFile: { file: string, name?: string, date: string}, type?: string) {
    const base64str = reqFile.file;

    const binary = atob(base64str.replace(/\s/g, ''));
    const len = binary.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i);
    }

    let file: any = type ?
        new Blob([view], { type: type }) :
        new Blob([view], { type: 'application/pdf' });

    file.name = reqFile.name;
    file.lastModified = new Date(reqFile.date);
    var url = URL.createObjectURL(file as Blob);

    file.preview = url;
    return file as File;
}

export function arraySwapElements<T>(arr: T[], fromIndex: number, toIndex: number) {
    const copy = [...arr];
    copy.splice(fromIndex, 1);
    copy.splice(toIndex, 0, arr[fromIndex]);
    return copy;
}

export function resolveBlob(response: AxiosResponse) {
    const headerval = response.headers['content-disposition'];
    if (headerval != null) {
        let filename = headerval.split(';')[2].split('=')[1].replace('"', '').replace('"', '').replace("UTF-8''", '');
        filename = decodeURI(filename);
        return new File([response.data], filename);
    } else {
        throw new Error(response.data);
    }
}