import { Alert, LinearProgress, Snackbar, styled } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ApplicationContext, ClientSettingsContext, IClientSettingsContext, IUserContext, UserContext } from '../../App';
import ISignInResponse from '../Login/ISignInResponse';
import PersistentDrawer from './PersistentDrawer';
import PreScreen from './PreScreen';
import { AlertColor } from '@mui/material';
import { checkToken } from '../../queries/Login';
import { getClientSettings } from '../../queries/Settings';

export const drawerWidth = 240;
export const appbarHeight = 64;

const GlobalLoader = styled(LinearProgress)({
    zIndex: 9998,
    width: '100%',
    position: 'fixed'
});

const Layout = (props: {
    children: ReactNode,
    message: { text: string, type: AlertColor } | null,
    hideMessage: () => void
}) => {

    const appCtx = useContext(ApplicationContext);
    const usrCtx = useContext<IUserContext | null>(UserContext);
    const settingsCtx = useContext<IClientSettingsContext | null>(ClientSettingsContext);
    const [showPreScreen, setShowPreScreen] = useState(true);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!localStorage.getItem('accessToken')) {
            navigate("/login");
            setShowPreScreen(false);
        };
    }, [navigate]);

    const setUserNotLoginState = useCallback(() => {
        appCtx!.setLoading(false);
        usrCtx!.setUser(null);
        localStorage.removeItem('accessToken');
        navigate(`/login`);
    }, [appCtx, usrCtx, navigate]);

    useQuery(["ClientSettings"], getClientSettings, {
        onSuccess: (response) => settingsCtx?.setClientSettings(response)
    });

    useEffect(() => {
        if (usrCtx?.user && location.pathname.includes("/login")) {
            navigate("/");
        }
    }, [usrCtx?.user, location, navigate]);

    useQuery<AxiosResponse<ISignInResponse>, AxiosError>(['token'], checkToken, {
        refetchInterval: 5 * 60 * 1000,
        enabled: !!localStorage.getItem("accessToken"),
        onSettled: () => {
            setShowPreScreen(false);
        },
        onSuccess: (response) => {
            if (response.data.errorCode !== 200) {
                setUserNotLoginState();
                return;
            };

            if (response.data.result.accessToken) {
                localStorage.setItem('accessToken', response.data.result.accessToken);
                axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.result.accessToken}`;
            }

            usrCtx?.setUser(response.data.result.user);
        },
        onError: () => {
            setUserNotLoginState();
        },
        retry: false
    });

    return (
        <>
            {
                appCtx?.loading &&
                <GlobalLoader
                    color='error'
                />
            }
            {
                showPreScreen &&
                <PreScreen />
            }
            <PersistentDrawer
                hideDrawer={!usrCtx?.user}
            >
                {props.children}
            </PersistentDrawer>
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={props.message !== null}
                autoHideDuration={4000}
                onClose={() => props.hideMessage()}
            >
                <Alert elevation={6} variant="filled" severity={props.message?.type}>
                    {props.message?.text}
                </Alert>
            </Snackbar>
        </>
    );
}

export default Layout;
