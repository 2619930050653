import React from 'react';
import { Box } from "@mui/material";

const PreScreen = () => {
    return (
        <Box
            position='fixed'
            height='100%'
            width='100%'
            top={5}
            bgcolor='white'
            display='flex'
            alignItems='center'
            justifyContent='center'
            flexDirection='column'
            zIndex={9999}
        >
            <img
                alt='System Logo'
                src='/logo.svg'
                style={{
                    maxHeight: 350,
                    maxWidth: 400,
                    width: '30%'
                }}
            />
        </Box>
    );
}

export default PreScreen;