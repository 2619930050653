import { QueryFunctionContext } from '@tanstack/react-query';
import axios from 'axios';
import NotificationDTO from '../dto/NotificationDTO';

export async function getLastNotifications(context: QueryFunctionContext): Promise<NotificationDTO[]> {
    const res = await axios.get(`api/Notifications/GetLastNotifications`);
    return (res.data.result.info.rows as NotificationDTO[])!;
}
export async function readNotification(id: number): Promise<number> {
    const res = await axios.get(`api/Notifications/Read?id=${id}`);
    return (res.data as number)!;
}