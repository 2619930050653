import axios, { AxiosPromise } from 'axios';
import IPasswordRecoveryResponse from '../components/Login/IPasswordRecoveryResponse';
import ISignInResponse from '../components/Login/ISignInResponse';

export const signInPost = (userLogin: { email: string, password: string }): AxiosPromise<ISignInResponse> => {
    return axios.post('api/Login/SignIn', { email: userLogin.email, password: userLogin.password });
}

export const signOutPost = async (userInfo: { email: string }) => {
    return await axios.post('/api/Login/SignOut', { email: userInfo.email });
}

export const checkToken = async () => {
    return await axios.get("/api/Login/CheckToken");
}

export const recoverPassword = (userInfo: { email: string }): AxiosPromise<IPasswordRecoveryResponse> => {
    return axios.post('/api/Login/RecoverPassword', { email: userInfo.email });
}