import { LooseObject } from "../util/LooseObject";

export const DATE_FORMAT: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
};

export const DATE_DATEPICKER_FORMAT: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
};

export const DATE_TIME_FORMAT: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    second: '2-digit'
};

export const DATE_TIME_FORMAT_MOSCOW: Intl.DateTimeFormatOptions = {
    timeZone: "Europe/Moscow",
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    second: '2-digit'
};

//todo: change to array
export const QUESTION_NAMES: LooseObject = {
    group: 'Группа вопросов',
    variantTextGroup: 'Бальная оценка',
    date: 'Ввод даты',
    bool: 'Вопрос Да / Нет',
    int: 'Числовой вопрос',
    radio: 'Одиночный выбор',
    variants: 'Интервальный выбор',
    variantGroup: 'Матричный выбор',
    table: 'Таблица',
    medicationTable: 'Таблица приёма препаратов',
    volume: 'Метод усеченных конусов (Объем)',
    check: 'Множественный выбор',
    interval: 'Оценка от 0 до 10',
    textField: 'Текстовый вопрос', 
    picture: 'Картинка',
    text: 'Текст',
    average: "Среднее",
    accident: "Ссылка на заполнение НЯ"
};

export const QUESTION_NAMES_ACCIDENT: LooseObject = {
    date: 'Ввод даты',
    bool: 'Вопрос Да / Нет',
    int: 'Числовой вопрос',
    radio: 'Одиночный выбор',
    group: 'Группа вопросов',
    table: 'Таблица',
    check: 'Множественный выбор',
    textField: 'Текстовый вопрос',
    text: 'Текст'
};

export const ItemTypes = {
    QUESTION_EDIT: 'question_edit',
    QUESTION_TEMPLATE: 'question_template',
    GROUP_TEMPLATE: 'group_template'
}